import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0];

export const dictionary = {
		"/(private)": [~4,[2]],
		"/(private)/export": [5,[2]],
		"/(private)/import": [6,[2]],
		"/(private)/language": [7,[2]],
		"/(private)/link": [8,[2]],
		"/(public)/login": [22],
		"/(public)/movie/[id]": [~23],
		"/(private)/onboarding/follow": [9,[2]],
		"/(private)/onboarding/movies": [10,[2]],
		"/(private)/onboarding/profile": [11,[2]],
		"/(private)/onboarding/streaming": [12,[2]],
		"/(private)/palme": [13,[2]],
		"/(public)/person/[id]": [~24],
		"/(private)/profile": [14,[2]],
		"/(private)/recommendations": [~15,[2]],
		"/(private)/script": [16,[2]],
		"/sentry-example": [26],
		"/(public)/show/[id]": [~25],
		"/(private)/test": [17,[2]],
		"/(private)/theater": [18,[2]],
		"/(private)/top_OLD": [20,[2]],
		"/(private)/top": [19,[2]],
		"/(private)/watchlist/[id]": [~21,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';